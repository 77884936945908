import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Toptitle from "../components/toptitle"
import styled from "styled-components"

const Contact = () => (
  <Layout>
    <SEO title="お問い合わせ" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <Toptitle>Contact</Toptitle>
        <div className="pt-32 lg:mt-16 lg:pt-0">
          <h1 className="font-mincho text-3xl text-primary">お問い合わせ</h1>
        </div>
        <MobileWrapper className="lg:mt-8">
          <form action="https://formspree.io/f/mgednlnl" method="POST">
            <div>
              <label htmlFor="name" className="mt-4 mb-2 block font-gothic">
                お名前
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    className="w-full rounded border border-primary py-2 px-8 lg:w-2/3"
                    required
                  />
                </div>
              </label>
            </div>
            <div>
              <label htmlFor="email" className="mt-4 mb-2 block font-gothic">
                御社名
                <div className="mt-1">
                  <input
                    type="text"
                    name="company"
                    className="w-full rounded border border-primary py-2 px-8 lg:w-2/3"
                  />
                </div>
              </label>
            </div>
            <div>
              <label htmlFor="email" className="mt-4 mb-2 block font-gothic">
                電話番号
                <div className="mt-1">
                  <input
                    type="text"
                    name="phone"
                    className="w-full rounded border border-primary py-2 px-8 lg:w-2/3"
                    required
                  />
                </div>
              </label>
            </div>
            <div>
              <label htmlFor="email" className="mt-4 mb-2 block font-gothic">
                メールアドレス
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="w-full rounded border border-primary py-2 px-8 lg:w-2/3"
                    required
                  />
                </div>
              </label>
            </div>
            <label htmlFor="email" className="mt-4 mb-2 block font-gothic">
              お問い合わせ
              <div className="mt-1">
                <textarea
                  name="message"
                  className="h-96  w-full rounded border border-primary py-2 px-8 lg:w-2/3"
                  required
                ></textarea>
              </div>
            </label>
            <button
              type="submit"
              className="mt-4 w-full rounded bg-primary py-4 px-8 font-mincho text-white hover:bg-primaryHover lg:w-2/3"
            >
              送信
            </button>
          </form>
        </MobileWrapper>
      </section>
    </div>
  </Layout>
)

const MobileWrapper = styled.div`
  @media screen and (max-width: 1023px) {
    margin: 0 32px 0 32px;
  }
`

export default Contact
